<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-dialog v-model="showDialog">
    <v-card>
      <v-card-title>Edit Animal</v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col>
              <v-text-field v-model="animal.externalId" label="External ID"></v-text-field>
            </v-col>
            <v-col>
              <v-select :items="typesAvailable" label="Type" v-model="animal.type"></v-select>
            </v-col>
            <v-col>
              <v-select :items="breedsAvailable" label="Breed" v-model="animal.breed"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="animal.femaleParentId" label="Female Parent ID"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="animal.dateOfBirth" label="Date of birth"></v-text-field>
            </v-col>
            <v-col>
              <v-select :items="sexAvailable" label="Sex" v-model="animal.sex"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select :items="lifeStatusAvailable" label="Sex" v-model="animal.lifeStatus"></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn data-cy="editAnimalClose" @click="showDialog = false"> Cancel </v-btn>
        <v-btn data-cy="editAnimalSave" color="primary" @click="saveEditedItem(animal)">Update</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import i18n from '@/i18n'

export default {
  props: {
    show: Boolean,
    animal: Object
  },

  data: () => ({
    typesAvailable: [],
    breedsAvailable: [],
    sexAvailable: [
      {
        text: i18n.t('terms.male'),
        value: 'MALE'
      },
      {
        text: i18n.t('terms.female'),
        value: 'FEMALE'
      }
    ],
    lifeStatusAvailable: [
      {
        text: i18n.t('terms.alive'),
        value: 'ALIVE'
      },
      {
        text: i18n.t('terms.sick'),
        value: 'SICK'
      },
      {
        text: i18n.t('terms.dead'),
        value: 'DEAD'
      }
    ]
  }),

  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.error = null
        this.$emit('input', value)
      }
    }
  },

  beforeMount () {
    this.loadTypes()
    this.loadBreeds()
  },

  methods: {
    loadTypes () {
      fetch('/api/animals/types', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          data.forEach((item) => {
            this.typesAvailable.push({
              text: item,
              value: item
            })
          })
        })
    },

    loadBreeds () {
      fetch('/api/animals/breeds', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          data.forEach((item) => {
            this.breedsAvailable.push({
              text: item,
              value: item
            })
          })
        })
    },

    saveEditedItem (item) {
      fetch(`/api/animals/${item.animalId}`, {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        },
        body: JSON.stringify(item)
      })
        .then((response) => {
          if (response.status === 201) {
            this.showDialog = false
            this.$emit('reload')
          }
        })
    }

  }
}
</script>
