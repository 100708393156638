<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab v-for="item in items" :key="item.link" :to="item.link">{{ item.text }}</v-tab>
    </v-tabs>

    <router-view />
  </div>
</template>

<script>
import i18n from '@/i18n'

export default {
  name: 'LivestockHome',
  components: {
  },
  data () {
    return {
      tab: null,
      items: [
        {
          text: i18n.t('inventory.tab.overview'),
          link: '/livestock'
        },
        {
          text: i18n.t('inventory.tab.inventory'),
          link: '/livestock/inventory'
        },
        {
          text: i18n.t('inventory.tab.breeding'),
          link: '/livestock/breeding'
        },
        {
          text: i18n.t('inventory.tab.potentialMothers'),
          link: '/livestock/potential-mothers'
        }
      ]
    }
  }
}
</script>
