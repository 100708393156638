<template>
  <v-dialog v-model="showDialog">
    <v-card>
      <v-card-title class="text-h5">{{ $t('inventory.deleteAnimalDialog.title') }}</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn data-cy="closeDeleteItem" color="blue darken-1" text @click="showDialog = false">{{
          $t('inventory.deleteAnimalDialog.cancel')
        }}</v-btn>
        <v-btn data-cy="confirmDeleteItem" color="primary" dark class="mb-2" @click="deleteItemConfirm">{{
          $t('inventory.deleteAnimalDialog.ok')
        }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    animal: Object
  },

  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.error = null
        this.$emit('input', value)
      }
    }
  },

  methods: {
    deleteItemConfirm () {
      fetch(`/api/animals/${this.animal.animalId}`, {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => {
          if (response.status === 200) {
            this.showDialog = false
            this.$emit('reload')
          } else console.error('Cannot delete. Status: ', response.status)
        })
        .catch((error) => console.error(error))
    }
  }
}
</script>
