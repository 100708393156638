<template>
  <div>
    <h2>Offspring</h2>
    <div class="action-item">
      <div v-if="offsprings.length > 0">
        This animal has had the following offsprings:
        <table>
          <thead>
            <tr>
              <th>{{ $t('terms.tableHeaders.earMark') }}</th>
              <th>{{ $t('terms.tableHeaders.sex') }}</th>
              <th>{{ $t('terms.tableHeaders.age') }}</th>
              <th>{{ $t('terms.tableHeaders.lifeStatus') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="offspring in offsprings" :key="offspring.animalId">
              <td><router-link :to="{ name: 'AnimalDetails', params: { animalId: offspring.animalId } }">{{
                offspring.externalId
              }}</router-link></td>
              <td>{{ formatSex(offspring.sex) }}</td>
              <td>{{ formattedAge(offspring.ageInMonths) }}</td>
              <td>{{ formatLifeStatus(offspring.lifeStatus) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        This animal has no offspring.
      </div>
      <v-btn @click.stop="addOffspringDialog()">{{ $t('offspringSection.btnAddOffspring') }}</v-btn>
    </div>
    <AddAnimalDialog :show="showAddOffspringDialog" @input="showAddOffspringDialog = $event" :parentAnimal="animal"
      v-on:reload="$emit('reload')" />
  </div>
</template>

<script>
import AddAnimalDialog from './AddAnimalDialog.vue'
import formatting from '@/mixins/formatting.js'

export default {
  props: {
    animal: Object
  },

  components: {
    AddAnimalDialog
  },

  mixins: [formatting],

  data: () => ({
    showAddOffspringDialog: false,
    offsprings: []
  }),

  watch: {
    animal: function (newAnimal) {
      if (newAnimal) {
        newAnimal.offspringIds.forEach(offspringId => {
          this.loadOffspring(offspringId)
        })
      }
    }
  },

  methods: {
    addOffspringDialog () {
      this.showAddOffspringDialog = true
    },

    loadOffspring (animalId) {
      fetch(`/api/animals/${animalId}`, {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(response.statusText)
          }
          return response
        })
        .then((response) => response.json())
        .then((data) => {
          this.offsprings.push(data)
        })
    }
  }
}
</script>

<style scoped>
TABLE {
  text-align: left;
  margin-top: 0.5em;
}

TABLE TD {
  padding-right: 0.5em;
}
</style>
