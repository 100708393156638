<template>
  <div>
    <h1>{{ $t('animalDetails.title') }}</h1>
    <v-alert v-if="error" type="error">{{ error }}</v-alert>

    <div v-if="!error">
      <div class="table-like animal-header">
        <div>
          <div class="label">{{ $t('animalDetails.EarTag') }}</div>
          <div> {{ item.externalId }}</div>
        </div>
        <div>
          <div class="label">
            {{ $t('animalDetails.animalType') }}
          </div>
          <div>
            {{ item.type }}
          </div>
        </div>
        <div>
          <div class="label">
            {{ $t('animalDetails.animalBreed') }}
          </div>
          <div>
            {{ item.breed }}
          </div>
        </div>
        <div>
          <div class="label">
            {{ $t('animalDetails.lifeStatus') }}
          </div>
          <div>
            {{ formatLifeStatus(item.lifeStatus) }}
          </div>
        </div>
        <div>
          <div class="label">
            {{ $t('animalDetails.dateOfBirth') }}
          </div>
          <div>
            {{ formatDate(item.dateOfBirth) }} ({{ formattedAge(item.ageInMonths) }})
          </div>
        </div>
        <div>
          <div class="label">
            {{ $t('animalDetails.sex') }}
          </div>
          <div>
            {{ formatSex(item.sex) }}
          </div>
        </div>
        <div>
          <div class="label">
            {{ $t('animalDetails.femaleParentId') }}
          </div>
          <div>
            {{ item.femaleParentId }}
          </div>
        </div>
        <div>
          <div class="label">
            {{ $t('animalDetails.statusIndicators') }}
          </div>
          <div>
            {{ item.statusIndicators }}
          </div>
        </div>
      </div>

      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header class="rollup-header">{{
            $t('animalDetails.observations')
          }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-textarea v-model="animalComment.comment" :label="$t('animalDetails.comment')" />
            <v-btn @click.stop="comment(item.animalId)">{{ $t('terms.save') }}</v-btn>
          </v-expansion-panel-content>
          <v-expansion-panel-content v-for="(entry, index) in item.comments" :key="index">
            {{ formatDateTime(entry.when) }}, {{ entry.comment }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header class="rollup-header">{{
            $t('animalDetails.medicalHistory')
          }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-textarea data-cy="sickAnimalComment" v-model="animalComment.comment"
              :label="$t('animalDetails.comment')" />
            <v-btn color="red" @click.stop="markAsSick(item.animalId)">{{ $t('animalDetails.markAsSick') }}</v-btn>
            <v-btn color="green" @click.stop="markAsHealthy(item.animalId)">{{
              $t('animalDetails.markAsHealthy')
            }}</v-btn>
          </v-expansion-panel-content>
          <v-expansion-panel-content v-for="(entry, index) in item.medicalHistory" :key="index">
            {{ formatDateTime(entry.when) }}, {{ entry.comment }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <OffspringSection :animal="item" v-on:reload="loadAnimal($route.params.animalId)" />

      <h2>{{ $t('animalDetails.maintenance.title') }}</h2>
      <div class="maintenance-zone">
        <EarTagSection :animal="item" v-on:reload="loadAnimal($route.params.animalId)" />
      </div>

      <h2>{{ $t('animalDetails.salesZone') }}</h2>
      <div class="sales-zone">
        <div class="action-item">
          <dl>
            <dt>{{ $t('animalDetails.dtOfferForSale') }}</dt>
            <dd v-if="item.readyForSale">{{
              $t('animalDetails.ddForSale', {
                forSaleSince:
                  formatDate(item.readyForSaleSince)
              })
            }}</dd>
            <dd v-else>{{ $t('animalDetails.ddOfferForSale') }}</dd>
          </dl>
          <v-btn v-if="item.readyForSale" data-cy="stopSellAnimal" @click.stop="sellAnimalDialog()">
            {{ $t('animalDetails.btnStopSelling') }}
          </v-btn>
          <v-btn v-if="!item.readyForSale" data-cy="sellAnimal" @click.stop="sellAnimalDialog()">
            {{ $t('animalDetails.btnStartSelling') }}
          </v-btn>
        </div>
      </div>

      <h2>{{ $t('animalDetails.dangerZone') }}</h2>
      <div class="danger-zone">
        <div class="action-item">
          <dl>
            <dt>{{ $t('animalDetails.dtDeleteRecord') }} </dt>
            <dd>{{ $t('animalDetails.ddDeleteRecord') }} </dd>
          </dl>
          <v-btn data-cy="deleteItem" color="danger" @click.stop="deleteItem(item)">
            Delete this animal record
          </v-btn>
        </div>
        <div class="action-item">
          <dl>
            <dt>{{ $t('animalDetails.dtEditAnimal') }} </dt>
            <dd>{{ $t('animalDetails.ddEditAnimal') }} </dd>
          </dl>
          <v-btn data-cy="deleteItem" @click.stop="editItem()">
            {{ $t('animalDetails.btnEditAnimal') }}
          </v-btn>
        </div>
      </div>

      <SellAnimalDialog :show="showSellAnimalDialog" @input="showSellAnimalDialog = $event" :animal="item"
        v-on:reload="loadAnimal($route.params.animalId)" />
      <EditAnimal :show="showEditDialog" @input="showEditDialog = $event" :animal="editedItem"
        v-on:reload="loadAnimal($route.params.animalId)" />
      <DeleteAnimal :show="showDeleteDialog" @input="showDeleteDialog = $event" :animal="editedItem"
        v-on:reload="loadAnimal($route.params.animalId)" />
    </div>
  </div>
</template>

<script>
/* TODO list
  - sort comments in descending order (medical and otherwise)
*/
import SellAnimalDialog from './SellAnimalDialog.vue'
import EditAnimal from './EditAnimal.vue'
import DeleteAnimal from './DeleteAnimal.vue'
import EarTagSection from './EarTagSection.vue'
import OffspringSection from './OffspringSection.vue'
import formatting from '@/mixins/formatting'

export default {
  data: () => ({
    animalComment: {
      comment: ''
    },
    item: {},
    error: '',
    editedItem: {},
    showSellAnimalDialog: false,
    showEditDialog: false,
    showDeleteDialog: false
  }),

  components: { SellAnimalDialog, EditAnimal, DeleteAnimal, EarTagSection, OffspringSection },

  mixins: [formatting],

  beforeMount () {
    this.loadAnimal(this.$route.params.animalId)
  },

  watch: {
    $route (to, from) {
      if (to.params.animalId !== from.params.animalId) {
        this.loadAnimal(to.params.animalId)
      }
    }
  },

  methods: {
    sortByDateTime (list) {
      return list.sort(function (a, b) {
        return a.when > b.when ? -1 : 1
      })
    },

    loadAnimal (animalId) {
      fetch(`/api/animals/${animalId}`, {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(response.statusText)
          }
          return response
        })
        .then((response) => response.json())
        .then((data) => {
          if (data.medicalHistory) { this.sortByDateTime(data.medicalHistory) }

          if (data.comments) { this.sortByDateTime(data.comments) }

          this.item = data
        })
        .catch((error) => {
          this.error = error
        })
    },

    markAsSick (animalId) {
      this.storeComment(`/api/animals/${animalId}/sick`, animalId)
    },

    markAsHealthy (animalId) {
      this.storeComment(`/api/animals/${animalId}/healthy`, animalId)
    },

    comment (animalId) {
      this.storeComment(`/api/animals/${animalId}/comment`, animalId)
    },

    storeComment (url, animalId) {
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        },
        body: JSON.stringify(this.animalComment)
      })
        .then((response) => {
          if (response.status === 200) {
            this.animalComment.comment = ''
            this.loadAnimal(animalId)
          }
        })
    },

    sellAnimalDialog () {
      this.showSellAnimalDialog = true
    },

    editItem () {
      this.editedItem = { ...this.item }
      this.showEditDialog = true
    },

    deleteItem (item) {
      this.editedItem = Object.assign({}, item)
      this.showDeleteDialog = true
    }

  }
}
</script>

<style>
H2 {
  margin-top: 1em;
}

DIV.danger-zone {
  margin-top: 1em;
  margin-bottom: 1em;
  border-radius: 1em;
  border-color: red;
  border-style: solid;
  border-width: thin;
}

DIV.action-item {
  display: grid;
  grid-template-columns: 70% 30%;
  padding: 1em;
}

@media only screen and (max-width: 400px) {
  DIV.action-item {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
  }
}

DT {
  font-weight: bold;
}

.rollup-header {
  font-weight: bold;
}

DIV.table-like {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 1em;
}

@media only screen and (max-width: 400px) {
  DIV.table-like {
    display: flex;
    flex-wrap: wrap;
  }
}

DIV.animal-header {
  margin: 1em 0 1em 0;
}

DIV.label {
  font-weight: bold;
  white-space: nowrap;
}
</style>
