<template>
  <v-data-table :items="animals" :headers="headers" sort-by="daysAfterBirth" :sort-desc="true">
    <template v-slot:[`item.ageInMonths`]="{ item }">
      {{ formattedAge(item.ageInMonths) }}
    </template>
    <template v-slot:[`item.offspringAgeInMonths`]="{ item }">
      {{ formattedAge(item.offspringAgeInMonths) }}
    </template>
  </v-data-table>
</template>

<script>
import i18n from '@/i18n'
import formatting from '@/mixins/formatting'

export default {
  name: 'BreedingList',
  components: {},
  mixins: [formatting],

  data: () => ({
    animals: [],
    headers: [
      { text: i18n.t('breeding.motherId'), align: 'start', value: 'externalId' },
      { text: i18n.t('terms.tableHeaders.species'), align: 'start', value: 'type' },
      { text: i18n.t('terms.tableHeaders.breed'), align: 'start', value: 'breed' },
      { text: i18n.t('terms.tableHeaders.age'), align: 'start', value: 'ageInMonths' },
      { text: i18n.t('breeding.daysAfterBirth'), align: 'start', value: 'daysAfterBirth' },
      { text: i18n.t('breeding.offspringId'), align: 'start', value: 'offspringExternalId' },
      { text: i18n.t('breeding.offspringBirth'), align: 'start', value: 'offspringDOB' },
      { text: i18n.t('breeding.offspringAge'), align: 'start', value: 'offspringAgeInMonths' }
    ]
  }),

  beforeMount: function () {
    this.loadAnimals()
  },

  methods: {
    loadAnimals () {
      fetch('/api/animals/with-offspring', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.animals = data.animals
        })
    }
  }
}
</script>
