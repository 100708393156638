<template>
  <v-data-table :items="animals" :headers="headers" sort-by="lastOffspringInMonths" :sort-desc="true">
    <template v-slot:[`item.details`]="{ item }">
      <v-icon large @click="showDetails(item.animalId)">mdi-folder-open-outline</v-icon>
    </template>
    <template v-slot:[`item.potentiallyPregnant`]="{ item }">
      <v-icon v-if="item.potentiallyPregnant" @click="markPregnant(item)">mdi-check</v-icon>
      <v-icon v-else large @click="markPregnant(item)">mdi-rocket-launch-outline</v-icon>
    </template>
    <template v-slot:[`item.ageInMonths`]="{ item }">
      {{ formattedAge(item.ageInMonths) }}
    </template>
    <template v-slot:[`item.lastOffspringInMonths`]="{ item }">
      {{ formattedAgeInDays(item.lastOffspringInMonths) }}
    </template>
  </v-data-table>
</template>

<script>
import i18n from '@/i18n'
import formatting from '@/mixins/formatting'

export default {
  name: 'PotentialMothers',
  components: {},
  mixins: [formatting],

  data: () => ({
    animals: [],
    headers: [
      { text: 'Details', align: 'start', value: 'details' },
      { text: i18n.t('breeding.motherId'), align: 'start', value: 'externalId' },
      { text: i18n.t('terms.tableHeaders.potentiallyPregnant'), align: 'start', value: 'potentiallyPregnant' },
      { text: i18n.t('terms.tableHeaders.species'), align: 'start', value: 'type' },
      { text: i18n.t('terms.tableHeaders.breed'), align: 'start', value: 'breed' },
      { text: i18n.t('terms.tableHeaders.age'), align: 'start', value: 'ageInMonths' },
      { text: i18n.t('breeding.lastOffspringInMonths'), align: 'start', value: 'lastOffspringInMonths' },
      { text: i18n.t('breeding.offsprings'), align: 'start', value: 'offspringIds' }
    ]
  }),

  beforeMount: function () {
    this.loadAnimals()
  },

  methods: {
    loadAnimals () {
      fetch('/api/animals/potential-mothers', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.animals = data.animals
        })
    },

    markPregnant (item) {
      fetch(`/api/animals/potential-mothers/${item.animalId}/mark-as-pregnant`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(() => {
          this.loadAnimals()
        })
    },

    showDetails (animalId) {
      this.$router.push({ name: 'AnimalDetails', params: { animalId } })
    }

  }
}
</script>
