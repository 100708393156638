<template>
  <div>
    <h2>{{ $t('livestock.overview.lifeStatus.title') }}</h2>
    <p v-for="report in report.lifeStatusReports" :key="report.status">
      <span>{{ $t('livestock.overview.lifeStatus.aliveLineMany', {
        aliveCount: report.lifeStatusCounts.ALIVE, type:
          formatAnimalType(report.animalType)
      }) }}</span>

      <span v-if="report.lifeStatusCounts.SICK === 1">{{ $t('livestock.overview.lifeStatus.sickAppendixOne', {
        sickCount:
          report.lifeStatusCounts.SICK
      }) }}</span>
      <span v-else>{{ $t('livestock.overview.lifeStatus.sickAppendixMany', { sickCount: report.lifeStatusCounts.SICK })
      }}</span>
    </p>

    <h2>{{ $t('livestock.overview.animalsOnSale.title') }}</h2>
    <p v-for="report in report.salesStatusReports" :key="report.status">
      {{ report.readyForSaleCount }} {{ formatAnimalType(report.animalType) }} are on sale
    </p>

    <h2>{{ $t('livestock.overview.upcomingEvents.title') }}</h2>
    {{ report.upcomingEventsReports }}
  </div>
</template>

<script>
export default {
  name: 'LivestockOverview',
  components: {
  },

  data: () => ({
    report: {}
  }),

  beforeMount () {
    this.loadReport()
  },

  methods: {
    formatAnimalType (animalType) {
      return this.$i18n.t('terms.animalTypes.' + animalType.toUpperCase())
    },

    loadReport () {
      fetch('/api/animals/situation-report', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.report = data
        })
    }
  }
}
</script>
