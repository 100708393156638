<template>
  <div>
    <v-data-table :items="animals" :headers="headers" group-by="lifeStatus" :search="search">
      <template v-slot:[`item.externalId`]="{ item }">
        <span :class="{ 'on-sale': item.readyForSale }">{{ item.externalId }}</span>
      </template>
      <template v-slot:[`item.details`]="{ item }">
        <v-icon large @click="showDetails(item.animalId)">mdi-folder-open-outline</v-icon>
      </template>
      <template v-slot:[`item.select`]="{ item }">
        <v-checkbox :value="isSelected(item.animalId)" @change="onSelect(item.animalId)" hide-details />
      </template>
      <template v-slot:[`item.dateOfBirth`]="{ item }">
        {{ item.dateOfBirth }} <span v-if="item.lifeStatus != 'DEAD'">({{ formattedAge(item.ageInMonths) }})</span>
      </template>
      <template v-slot:[`item.sex`]="{ item }">
        {{ $t('inventory.sex.' + item.sex) }}
      </template>
      <template v-slot:top>
        <v-toolbar>
          <v-btn data-cy="addAnimal" color="primary" dark @click.stop="showAddDialog = true">{{
            $t('inventory.addAnimal')
          }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn :disabled="!actionsEnabled" @click="markAllReadyForSale">{{ $t('inventory.markReadyForSale') }}</v-btn>
          <AddAnimalDialog :show="showAddDialog" @input="showAddDialog = $event" v-on:reload="$emit('reload')" />
        </v-toolbar>
        <v-text-field v-model="search" :label="$t('inventory.search')"></v-text-field>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import i18n from '@/i18n'
import AddAnimalDialog from './AddAnimalDialog.vue'
import formatting from '@/mixins/formatting'

export default {
  name: 'LifestockInventory',
  components: { AddAnimalDialog },
  mixins: [formatting],

  data: () => ({
    animals: [],
    headers: [
      { text: 'Select', align: 'start', value: 'select', sortable: false },
      { text: 'Details', align: 'start', value: 'details' },
      { text: 'ID', align: 'start', value: 'externalId' },
      { text: i18n.t('terms.tableHeaders.species'), align: 'start', value: 'type' },
      { text: i18n.t('terms.tableHeaders.breed'), align: 'start', value: 'breed' },
      { text: i18n.t('terms.tableHeaders.state'), align: 'start', value: 'lifeStatus' },
      { text: i18n.t('terms.tableHeaders.birth'), align: 'start', value: 'dateOfBirth' },
      { text: i18n.t('terms.tableHeaders.sex'), align: 'start', value: 'sex' },
      { text: i18n.t('terms.tableHeaders.femaleParent'), align: 'start', value: 'femaleParentId' }
    ],
    search: '',
    editedItem: {},
    animalsSelected: [],
    showAddDialog: false
  }),

  computed: {
    isSelected () {
      return (animalId) => {
        return this.animalsSelected.indexOf(animalId) !== -1
      }
    },

    actionsEnabled () {
      return this.animalsSelected.length > 0
    }
  },

  beforeMount: function () {
    this.loadAnimals()
  },

  methods: {
    onSelect (animalId) {
      const index = this.animalsSelected.indexOf(animalId)
      if (index === -1) {
        this.animalsSelected.push(animalId)
      } else {
        this.animalsSelected.splice(index, 1)
      }
    },

    markAllReadyForSale () {
      fetch('/api/animals/sale-ready', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.animalsSelected)
      })
        .then((response) => {
          if (response.ok) {
            this.animalsSelected = []
            this.loadAnimals()
          } else {
            console.error('error', response)
          }
        })
    },

    loadAnimals () {
      fetch('/api/animals', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.animals = data.animals
        })
    },

    sickAnimalDialog (item) {
      this.editedItem = item
      this.showSickDialog = true
    },

    editItem (item) {
      this.editedItem = item
      this.showEditDialog = true
    },

    showDetails (animalId) {
      this.$router.push({ name: 'AnimalDetails', params: { animalId } })
    }

  }
}
</script>

<style>
.on-sale {
  background-color: #fbd6f0;
}
</style>
