<template>
  <v-dialog v-model="showDialog">
    <v-card>
      <v-card-title>{{ $t('inventory.addAnimal') }}</v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col>
              <v-text-field v-model="animal.externalId"
                :label="$t('inventory.addAnimalDialog.externalId')"></v-text-field>
            </v-col>
            <v-col>
              <v-select :items="typesAvailable" :label="$t('inventory.addAnimalDialog.type')"
                v-model="animal.type"></v-select>
            </v-col>
            <v-col>
              <v-select :items="breedsAvailable" :label="$t('inventory.addAnimalDialog.breed')"
                v-model="animal.breed"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="animal.femaleParentId" :disabled="determineDisabled()"
                :label="$t('inventory.addAnimalDialog.femaleParentId')"></v-text-field>
            </v-col>
            <v-col>
              <v-menu ref="dateMenu" v-model="dateMenu" :close-on-content-click="false"
                :return-value.sync="animal.dateOfBirth" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="animal.dateOfBirth" :label="$t('inventory.addAnimalDialog.dateOfBirth')"
                    prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="animal.dateOfBirth" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="dateMenu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.dateMenu.save(animal.dateOfBirth)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-select :items="sexAvailable" :label="$t('inventory.addAnimalDialog.sex')"
                v-model="animal.sex"></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn data-cy="addAnimalClose" @click="showDialog = false">{{
          $t('inventory.addAnimalDialog.cancel')
        }} </v-btn>
        <v-btn data-cy="addAnimalSave" color="primary" @click="saveAddedItem(animal)">{{
          $t('inventory.addAnimalDialog.save')
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import i18n from '@/i18n'

export default {
  props: {
    show: Boolean,
    parentAnimal: Object
  },

  data: () => ({
    animal: {},
    dateMenu: false,
    typesAvailable: [],
    breedsAvailable: [],
    sexAvailable: [
      {
        text: i18n.t('terms.male'),
        value: 'MALE'
      },
      {
        text: i18n.t('terms.female'),
        value: 'FEMALE'
      }
    ],
    lifeStatusAvailable: [
      {
        text: i18n.t('terms.alive'),
        value: 'ALIVE'
      },
      {
        text: i18n.t('terms.sick'),
        value: 'SICK'
      },
      {
        text: i18n.t('terms.dead'),
        value: 'DEAD'
      }
    ]
  }),

  watch: {
    parentAnimal: function (newParentAnimal) {
      if (newParentAnimal) {
        this.animal.femaleParentId = newParentAnimal.externalId
      }
    }
  },

  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.error = null
        this.$emit('input', value)
      }
    }
  },

  beforeMount () {
    this.loadTypes()
    this.loadBreeds()
  },

  methods: {
    determineDisabled () {
      return this.parentAnimal !== undefined
    },

    loadTypes () {
      fetch('/api/animals/types', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          data.forEach((item) => {
            this.typesAvailable.push({
              text: item,
              value: item
            })
          })
        })
    },

    loadBreeds () {
      fetch('/api/animals/breeds', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          data.forEach((item) => {
            this.breedsAvailable.push({
              text: item,
              value: item
            })
          })
        })
    },

    saveAddedItem (item) {
      fetch('/api/animals', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        },
        body: JSON.stringify(item)
      })
        .then((response) => {
          if (response.status === 201) {
            this.showDialog = false
            this.$emit('reload')
          }
        })
    }

  }
}
</script>
