<template>
  <div>
    <div class="action-item">
      <dl>
        <dt>{{ $t('animalDetails.maintenance.dtMissingEarTag') }}</dt>
        <dd v-if="animal.earTagsOrdered">{{
          $t('animalDetails.maintenance.ddMissingEarTagOrdered', {
            dateOrdered:
              formatDate(animal.earTagsOrdered)
          }) }}</dd>
        <dd v-else>{{ $t('animalDetails.maintenance.ddMissingEarTag') }}</dd>
      </dl>

      <v-btn :disabled="isTruthy(animal.earTagsOrdered)" @click.stop="orderEarTagsDialog()">{{
        $t('animalDetails.maintenance.btnMissingEarTag')
      }}</v-btn>
    </div>
    <div v-if="animal.earTagsOrdered" class="action-item">
      <dl>
        <dt>{{ $t('animalDetails.maintenance.dtEarTagPutOn') }}</dt>
        <dd>{{ $t('animalDetails.maintenance.ddEarTagPutOn') }}</dd>
      </dl>
      <v-btn @click.stop="putOnEarTagsDialog()">{{
        $t('animalDetails.maintenance.btnEarTagsPutOn')
      }}</v-btn>
    </div>

    <OrderEarTagsDialog :show="showOrderEarTagsDialog" @input="showOrderEarTagsDialog = $event" :animal="animal"
      v-on:reload="$emit('reload')" />
    <PutOnEarTagsDialog :show="showPutOnEarTagsDialog" @input="showPutOnEarTagsDialog = $event" :animal="animal"
      v-on:reload="$emit('reload')" />
</div>
</template>

<script>
import OrderEarTagsDialog from './OrderEarTagsDialog.vue'
import PutOnEarTagsDialog from './PutOnEarTagsDialog.vue'

export default {
  props: {
    animal: Object
  },

  components: {
    OrderEarTagsDialog,
    PutOnEarTagsDialog
  },

  data: () => ({
    showOrderEarTagsDialog: false,
    showPutOnEarTagsDialog: false
  }),

  methods: {
    isTruthy (value) {
      if (value) { return true } else return false
    },

    formatDate (value) {
      if (value) {
        const date = new Date(value)
        return new Intl.DateTimeFormat(navigator.language).format(date)
      } else { return value }
    },

    orderEarTagsDialog () {
      this.showOrderEarTagsDialog = true
    },

    putOnEarTagsDialog () {
      this.showPutOnEarTagsDialog = true
    }

  }
}
</script>

<style scoped>
DT {
  font-weight: bold;
}
</style>
