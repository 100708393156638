<template>
  <v-dialog v-model="showDialog" :width="width">
    <v-card>
      <v-card-title class="text-h5">{{ $t('orderEarTagsDialog.title') }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-checkbox v-model="earTagOrder.leftEar" :label="$t('orderEarTagsDialog.leftEar')" />
          </v-col>
          <v-col>
            <v-checkbox v-model="earTagOrder.rightEar" :label="$t('orderEarTagsDialog.rightEar')" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn data-cy="closeButton" color="blue darken-1" text @click="showDialog = false">{{
          $t('terms.cancel')
        }}</v-btn>
        <v-btn data-cy="saveButton" color="primary" dark class="mb-2" @click="performOrderEarTags()">{{
          $t('terms.ok')
        }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    animal: Object
  },

  data: () => ({
    earTagOrder: {
      leftEar: true,
      rightEar: true
    }
  }),

  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.error = null
        this.$emit('input', value)
      }
    },

    width () {
      // TODO just to learn; could return a static value
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 350
        case 'sm': return 350
        case 'md': return 350
        case 'lg': return 350
        case 'xl': return 350
        default: return 350
      }
    }
  },

  methods: {
    performOrderEarTags () {
      fetch(`/api/animals/${this.animal.animalId}/order-ear-tags`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        },
        body: JSON.stringify(this.earTagOrder)
      })
        .then((response) => {
          if (response.status === 200) {
            this.showDialog = false
            this.$emit('reload')
          }
        })
    }
  }
}
</script>
