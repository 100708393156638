<template>
  <v-dialog v-model="showDialog">
    <v-card>
      <v-card-title v-if="animal.readyForSale" class="text-h5">Stop selling animal</v-card-title>
      <v-card-title v-else class="text-h5">Animal ready for sale</v-card-title>
      <v-card-text v-if="animal.readyForSale">
        Do you want to stop selling this animal?
      </v-card-text>
      <v-card-text v-else>
        Do you want to mark this animal as ready for sale?
      </v-card-text>
      <v-card-text>
        {{ animal.externalId }} of type {{ animal.type }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn data-cy="closeSellAnimalDialog" color="blue darken-1" text @click="showDialog = false">Cancel</v-btn>
        <v-btn data-cy="confirmReadyForSale" color="primary" dark class="mb-2" @click="confirmReadyForSale">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    animal: Object
  },

  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.error = null
        this.$emit('input', value)
      }
    }
  },

  methods: {
    confirmReadyForSale () {
      let url

      if (this.animal.readyForSale) { url = `/api/animals/${this.animal.animalId}/stop-sale` } else { url = `/api/animals/${this.animal.animalId}/sale-ready` }

      fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => {
          if (response.status === 200) {
            this.showDialog = false
            this.$emit('reload')
          }
        })
    }
  }
}
</script>
